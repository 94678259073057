/* KIPLING */

(function (app, $) {
	window.dataLayer = window.dataLayer || [];
	var dataLayer = window.dataLayer;

	$(".js-ajax-form-register-confirmation-page").click(function(e){
		e.preventDefault();
		var form = $(this).parents("form");
		var button = $(this);
		if(button && button.data("recaptchav3") == true && typeof grecaptcha != "undefined"){
			 grecaptcha.ready(function() {
		          grecaptcha.execute(button.data("sitekey"), {action: 'submit'}).then(function(token) {
		        	  $("input[name='grecaptcharesponse']").val(token);
		        	  form.submit();
		          });
		     });
		} else {
			form.submit();
		}
	});
	
	
	$(document).on('input', "input[name*='firstnam'], input[name*='lastname'], input[name*='email'], input[name*='pseudoEmail']", function(){
		$(this).val($(this).val().replace(/[^a-z0-9-\.-\@_]/gi, '').replace(/=/gi, '').replace(/\//gi, ""));
	});
	
	
	var intervalGoogle;
	$(document).on('click', '.js-google-pay-pdp', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		var googleScript = $(".pdp-googlepay").data('radialscriptgoogle');
		if(googleScript){
			var script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = googleScript;    
			document.head.appendChild(script);
		}
		
		app.progress.show($("body"));
		$("input[name='pid_master']").after('<input type="hidden" name="googlePay" class="googlePay" value="true">');
		$("#add-to-cart").trigger("click");		
		intervalGoogle = setInterval(function(){		
			if($(".success-url-googlepay").length){
				$(".ui-dialog").remove();
				clearInterval(intervalGoogle);
				var interValSub = setInterval(function(){
					if(typeof google != 'undefined' && typeof google.payments != 'undefined'){
						clearInterval(interValSub);
						app.progress.hide();
						$("#mini-cart .gpay-button-fill button").click();
					}
				}, 1500);				
			}
		}, 1500);								
	});
	
	$(document).on('click', '.js-student-discount', function(){
		dataLayer.push({
            'event':'student-discount-open'
		});
	});

	var cardPattern = {
				'MasterCard': /^5[1-5][0-9]{2,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/,
				'Visa':       /^4[0-9]{3,}$/,
				'Amex':       /^3[47][0-9]{2,}$/,
				'Discover':   /^6(?:011|5[0-9]{2})[0-9]{2,}$/,
				'UnionPay':   /^(62|88)\d+$/
	};

	$(document).on('keyup', '.card-number-formfield input', function(e){
			var cardNumber = $(this).val().replace(/ /g, '').replace(/X/g, '');
			$.each(cardPattern, function(i, v){
					var isValid = cardPattern[i].test(cardNumber);
					if(isValid){
							localStorage.setItem('cctype', i);
							if (i === 'UnionPay') {
								$("select[name$='dwfrm_billing_paymentMethods_creditCard_type']").val('Discover');
								$("select[name$='dwfrm_billing_paymentMethods_creditCard_type']").select2("val", 'Discover');
							} else {
								$("select[name$='dwfrm_billing_paymentMethods_creditCard_type']").val(i);
								$("select[name$='dwfrm_billing_paymentMethods_creditCard_type']").select2("val", i);
							}
							$('.credit-card--icon').attr({'data-card-image': i});
					}
			});
	});

	if( $('.credit-card--icon').length && localStorage.getItem('cctype')) {
		$('.credit-card--icon').attr({'data-card-image': localStorage.getItem('cctype')});
	}

	if ($('.confirmation-payment-method').length > 0) {
		$('.condirmatiom--cc-type').text(localStorage.getItem('cctype'));
	}

	if (app.gridloadmore !== undefined) {
		app.gridloadmore.afterinit = function() {
			if( jQuery("#compare-table").length > 0 ) {
				//dynamically set the height of empty slots in the compare table to the parent td's height
				$('div.emptycompareslot').each(function(){
				    // Get their parent td's height
				    var tdHeight = $(this).closest('td').height();

				    // Set the div's height to their parent td's height
				    $(this).height(tdHeight);
				});
			}
			/* For Our Favorite Things- Match current url to a left menu link to add a 'current' class */
			if( jQuery(".cat-nav-favorite").length > 0 ) {
				var url = location.href;
			    var activePage = url.substring(url.lastIndexOf('/')+1);
			    $('.cat-nav-favorite li a').each(function(){
			    	var currentPage = this.href.substring(this.href.lastIndexOf('/')+1);
			    	if (activePage == currentPage) {
			    		$(this).addClass('current');
			    	}
			    });
			}
		};
	}
	
	
	
	
	if($(".js-is-apple-pay-pdp-main").length && navigator.userAgent.toLowerCase().indexOf('safari/') > -1){

		try{
			if(ApplePaySession.canMakePayments()){
				$('.js-is-apple-pay-pdp-main').show();
				$(document).on('click', '.js-is-apple-pay-pdp-main', function(e){
					e.preventDefault();
					var button = $(this);
					$("body").addClass('nopopup');
					$(".pdpForm #add-to-cart").trigger("click");
					var il = setInterval(function(){
						if($(".dw-apple-pay-button").length){
							clearInterval(il);
							$(".js-is-apple-pay-pdp-main").attr('style', 'display:none !important');
							$(".js-is-apple-pay-pdp-main-wrapper").show();
							$(".dw-apple-pay-button").trigger('click');
							$(".dw-apple-pay-button").trigger('click');
							$(".js-is-apple-pay-pdp-main-wrapper").trigger('click');

							if (typeof dataLayer != "undefined"){
								if(app.isMobileUserAgent){
									dataLayer.push({
										'event':'add-to-cart-applepay-mobile'
					               });
								} else {
									dataLayer.push({
										'event':'add-to-cart-applepay-desktop'
					               });

								}
							}


						}
					}, 1000);
				});
			}
		}catch(e){}
	}
	
	
	$(document).on("click", "#smartgift-link", function(){
		if(app.isMobileUserAgent){
			dataLayer.push({
				'event':'smart-gift-mobile'
           });
		} else {
			dataLayer.push({
				'event':'smart-gift-desktop'
           });
		}
		if ($(this).data("is-recommender")) {
			var productTileEl = $(this).siblings(".product-tile");
			var smartGiftObj = {
				skuCode: $(this).data("pid").toString(),
				skuUrl: $(this).data("url").toString(),
				price: parseFloat($(productTileEl).find(".product-sales-price:eq(0)").text().trim().replace("$", "")),
				name: $(productTileEl).find("span.product-name-wrapper").text().trim().replace('\n', ' ').toString(),
				image: $(productTileEl).find(".thumb-link img").attr("src").toString(),
				quantity: "1"
			}
		} else {
			var smartGiftObj = {
				skuCode: $(this).data("pid").toString(),
				skuUrl: $(this).data("url").toString(),
				price: parseFloat($(".product-detail .product-price .price-sales:eq(0)").text().trim().replace("$", "")),
				name: $("h1").text().trim().replace('\n', ' ').toString(),
				image: $(".js-gallery-pdp img:eq(0)").attr("src").toString(),
				quantity: $(".js-mobile-quantity").val()			     
			};
		}
		console.log(smartGiftObj);
		if(typeof SGBasket != "undefined"){
			SGBasket.addItem(smartGiftObj);
		}							
	});
	//turnTo tabs
	$('.turnto--tabs-list li a:not(:first)').addClass('inactive');
	$('.turnto--tab-content').hide();
	$('.turnto--tab-content:first').show();

	$('.mobile-pdp-header-wrapper .mobile-name').click(function(e) {
		e.preventDefault();
		var productInfoTabs = $('.product-info-accordion .product-info-accordion-box ');

		for (var i = 0; i < 2; i++) {
			var triggerInfoTab = $(productInfoTabs[i]).find('.product-info-accordion-link.js-open-accordion-content');
			var checkInfoTab = $(productInfoTabs[i]).find('.product-info-accordion-link.js-open-accordion-content').hasClass('active');
			if (!checkInfoTab) {
				triggerInfoTab.trigger('click');
			}
		}

		$("html, body").animate({ scrollTop: $('.product-description-wrapper').offset().top + 35 - $('#header').height()}, 1500);
	});

	function clickReviewFromReviews() {
		$('#turnto--view-tab').trigger('click');
		$("html, body").animate({ scrollTop: $('#TTreviewsWrapper').offset().top - $('#header').height()}, 1500);
	}

	var reviewsLeftStarsBox = $(".TT2left .TTratingBox");
	var reviewsLeftRatingBox = $(".TT2left #TTreviewSummaryAverageRating");

	reviewsLeftStarsBox.click(function(e) {
		e.preventDefault();
		clickReviewFromReviews();
	});

	reviewsLeftRatingBox.click(function(e) {
		e.preventDefault();
		clickReviewFromReviews();
	});


	$('.turnto--tabs-list li a').click(function(){
    var t = $(this).attr('id');

		$('.turnto--tabs-list li a').removeClass('active');
		$(this).addClass('active');

	  if($(this).hasClass('inactive')){ //this is the start of our condition
	    $('.turnto--tabs-list li a').addClass('inactive');
	    $(this).removeClass('inactive');

	    $('.turnto--tab-content').hide();
	    $('.'+ t + 'Show').fadeIn('slow');
	 	}
	});

	$(document).on('change', '.newsletterSubscriptionForm input[id^="checkbox-privacy"]', function(){
		  var btn = $(this).parents("form").find('.signUpBtn');
		  var form = $(this).parents("form");
	      if($(this).prop('checked')){
	    	  btn.attr('disabled', false);
	      } else {
	    	  btn.attr('disabled', 'disabled');
	      }	      	      
	      if(form.find("input[name='grecaptcharesponse']").length){
	    	  var url = form.find("input[name='grecaptcharesponse']").data("initjs");
	    	  var script=document.createElement('script');
	    	  script.type='text/javascript';
	    	  script.src=url;
	    	  $("body").append(script);
	      }
    });
	
	
	

	$(document).on('change', 'input[name*="_addtoloyaltysystem"]', function(){
    	var afterChangeStatus = $(this).prop('checked');
		var form = $(this).parents("form");
		var listEl = form.find("input[name*='_addtoemaillist']");
    	if(afterChangeStatus){
    		listEl.prop('checked', false);
    		listEl.attr('disabled', true);
    		listEl.parent().find('label').addClass('disabled');
    	} else {
    		listEl.attr('disabled', false);
    		listEl.parent().find('label').removeClass('disabled');
    	}
    });

	var working = false;
	$(".login-account, #RegistrationForm, .login-create-account, #loginform, #create-account-popup").on('mouseenter', function(e){
		if(!working){
			working = true
			setTimeout(function(){
				var target = $(e.target);
				var btn = target.parents("form").find("input[id*='agreedwithprivacypolicy']");
				if(btn.length && target.parents("form").find("button[type='submit']").hasClass('disabled')){
					btn.parents(".form-row").find(".error-message-policy").show();
				}
				working = false;
			}, 1400);
		}
	});

	var backToTop = $('.pt_product-details .back-to-top');
	$(window).scroll( function(e) {
		var scroll = $(window).scrollTop();
		var backToTopButtonThreshold = 500;

		if (scroll > backToTopButtonThreshold) {
			backToTop.show();
		} else {
			backToTop.hide();
		}
	});

	backToTop.on("click", function(e){
		e.preventDefault();
		$(window).scrollTop(0);
	});
	
	if ($(".pt_product-details .productBody").length > 0){
		var fixedAddToCartPDP = $('.pt_product-details .add-to-cart.bottom-fixed');
		if (fixedAddToCartPDP.length > 0) {
			$(window).scroll( function(e) {
				var scroll = $(window).scrollTop();
				if($(".pt_product-details .add-to-cart-wrapper").length){
					if (scroll > $(".pt_product-details .add-to-cart-wrapper").offset().top) {
						fixedAddToCartPDP.show();
					} else {
						fixedAddToCartPDP.hide();
					}
				}
			});
		}
	}

	 //cokies get, set, delete functions
    function setCookie(name, value, options) {
        options = options || {};
        var expires = options.expires;

        if (typeof expires == "number" && expires) {
            var d = new Date();
            d.setTime(d.getTime() + expires * 1000);
            expires = options.expires = d;
        }
        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString();
        }

        value = encodeURIComponent(value);

        var updatedCookie = name + "=" + value;

        for (var propName in options) {
            updatedCookie += "; " + propName;
            var propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += "=" + propValue;
            }
        }

        document.cookie = updatedCookie;
    };

	function getCookie(name) {
        var matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));

        return matches ? decodeURIComponent(matches[1]) : undefined;
    };

    $(document).on('click', '.cookie-confirm', function() {
    	setCookie('cookiePrivacyPolicy', 'true', {expires: 5184000000, path: '/'});
    	$('.cookie-privacy-policy').hide();
    });

    if (typeof getCookie('cookiePrivacyPolicy') == "undefined" && !$('.user-account').length){
    	$('.cookie-privacy-policy').show();
    }

	$(document).on("click", ".compare--row-swatches ul li a", function(e){
		e.preventDefault();
		var img = $(this).find('img');
		
		var blockID = img.closest('.compare--row-swatches').data('id');
		
		var productId = $('.compareremovecell.section-header[data-id="'+blockID+'"] img')
		
		productId.attr('src', img.data('product-thumburl'));
		productId.closest('.section-header').find("button[type='submit']").attr('data-pid', img.data("pid"));
		
		$(this).parents("ul").find("a").removeClass("selected");
		$(this).addClass("selected");
		
		$(".compare--row-price[data-id="+ blockID +"] .product-variation-price").removeClass("selected");
		$(".compare--row-price[data-id="+ blockID +"] .product-variation-price[data-variation-pid="+ img.data("pid") +"]").addClass("selected");
	});
	
	if ($('.compare--row-swatches').length > 0) {
    	$('.compare--row-swatches').each(function (i, elem) {
    		if ($(elem).find('a.swatch.selected').length > 0) {
    			$(elem).find('a.swatch.selected').trigger('click');
    		}
    	});
    }

	$(document).on('click', '.compare--bottom-add-to-cart-container .compare--bottom-add-to-cart', function(e) {
		var checkMasterId = $(this).data('master-id');
		var triggerCartButton = $('.add-to-cart-desktop button[data-master=' + checkMasterId + ']');
		$(triggerCartButton).trigger('click');
	})

	$(document).on('click', '.compare--product-view-wrapper .js-viewMore-mob', function() {
		var target = $(this);
		var ulColorSwatch = target.parent('.swatch-toggle');
		var colorToggles = $('.mob-color-toggle');

		if (!ulColorSwatch.hasClass('mob-color-toggle')) {
			for (var i = 0; i < colorToggles.length; i++) {
				$(colorToggles[i]).removeClass('mob-color-toggle');
			}
			ulColorSwatch.addClass('mob-color-toggle');
		} else {
			ulColorSwatch.removeClass('mob-color-toggle');
		}
	})

	$(document).on('click keyup', '.bth--gift-cards', function(e) {
		if ((e.type == "click") || (e.keyCode == 13)) {
			$(this).toggleClass('active');
			$('#gift-certificate-form').toggleClass('active');
		}
	})

	if ($('.account-logout.js-header-logout.is-rewards-user').length) {
		$('.js--kp-check-user-status').addClass('kr-auth--loyalty');
		$('.mob-sigin--container').hide();
		$('.mobile-sigin--auth__container').show();
	} else if ($('.account-logout.js-header-logout').length) {
		$('.js--kp-check-user-status').addClass('kr-auth--no-loyalty');
		$('.mob-sigin--container').hide();
		$('.mobile-sigin--auth__container').show();
	} else {
		$('.js--kp-check-user-status').addClass('kr-no--auth');
		$('.mobile-signin').show();
		$('.mobile-sigin--auth__container').hide();
	}

	$(document).on('click', '.pt_cart .coupon-code-title, .pt_checkout .coupon-code-title, .pt_checkout .js-sidebasket-toggle', function(){
		$(this).toggleClass('active');
	});

	$(document).on('click', '.sidebar-basket .item-details-btn', function(){
		$(this).closest('.sidebar-basket-product').toggleClass('expanded');
	});

	$(document).on('blur', '#dwfrm_editprofile_customer_email' , function() {
		$('span[for=dwfrm_editprofile_customer_email]').addClass('active');
	});

	$(document).ready(function() {
		var summaryTab = $('#TurnToReviewsContent .TTreviewsCustomDimsSummaryWrap');
//		if (!$('.tt-c-review').length && $('#turnto--qa-tab').length) {
//			$('#turnto--qa-tab').trigger('click');
//		}

		if ($(window).width() < 480 && $(summaryTab).length) {
			$('<div class="TT-mob--cons-toggle">Summary</div>').insertBefore(summaryTab);

			$(document).on('click', '.TT-mob--cons-toggle', function() {
				$(summaryTab).toggleClass('active');
			})
		}
	});


	$(document).on('click', '.js-remove-coupon-code', function(e){
		e.preventDefault();
		var code = $(this).data("code");
		$("button[data-coupon-code-remove='"+code+"']").trigger("click");
	});


	$(document).on('click', '.js-ajax-form-submit', function(e, noRedirect){
		e.preventDefault();
		var url = $(this).data('link');
		app.progress.show();
		var w = 500;
		if ($(this).hasClass('user-signin') || $(this).parent().hasClass('si--su__redirect')) {
			w = 400;
		}
		var dlg = app.dialog.create({target:"#ajax-form-submit", options:{
			width:w,
			height:'auto',
			dialogClass: 'ui-submit-popup' + (noRedirect ? ' no-redirect' : ''),
			autoOpen:false,
			closeOnEscape: true,
			open:function() {
				app.validator.init();
				app.dialog.align(".ui-submit-popup");
			},
			position: { my: "center center",
			            at: "center center",
			            of: window,			            
				}
			}
		});

		app.ajax.load({
			url:app.util.ajaxUrl(url),
			target:dlg,
			callback: function () {
				app.progress.show();
				dlg.dialog("open");
			}
		});
	});
	
	
	function getAge(dateString) {
	    var today = new Date();
	    var birthDate = new Date(dateString);
	    var age = today.getFullYear() - birthDate.getFullYear();
	    var m = today.getMonth() - birthDate.getMonth();
	    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
	        age--;
	    }
	    return age;
	}
	
	function sendDataRegistration(data, url, source){
		$.ajax({
			type : 'POST',
			url : url,
			data : data,
			success : function(data){
				data = data.replace(/<!--.*?-->/g, "");
				if(data.length == "") {
						// window.location.reload();
					if ($('.ui-submit-popup.no-redirect').length) {
						window.location.reload();
					} else {
						if ($('#wrapper.pt_wishlist').length) {
							if (source && ['wishlist_plp_login', 'wishlist_plp_registration'].indexOf(source) > -1) {
								dataLayer.push({
									event: source
								});
							}
							window.location.href = app.urls.wishlistLanding;
						} else {
							window.location.href = $('#ajax-form-submit form').data('loginsuccess');
						}
					}
				} else {
					var html = $($.parseHTML(data));
					if(html.find(".error").length || html.find(".error-form").length) {
						$("#ajax-form-submit").empty().append(data);
						$(window).scrollTop($('#ajax-form-submit').offset().top);
					}
				}
			}
		});
	}
	
	$(document).on("submit", '#ajax-form-submit form', function(e){
		e.stopPropagation();	
		var form = $(this);
		var url = $(this).attr('action'),
			data = $(this).serialize(),
			form = $(this),
			source = $(this).data('source');
		
		if (!form.closest('.ui-submit-popup').hasClass('bf-register')) {
			e.preventDefault();
			
			if(form.find(".profile-birthday").length){
				var date_1 = form.find("select.day-of-birthday").val();
				var date_2 = form.find("select.month-of-birthday").val();
				var date_3 = form.find("select.year-of-birthday").val();
				if(date_1 > 0 && date_2 > 0 && date_3 > 0){
					if(getAge(date_2 + "/" + date_1 + "/" + date_3) < 18){
						form.find("input[name*='birthday']").parents(".form-row").addClass('error');
						return;	
					} 
				} else {
					form.find("input[name*='birthday']").parents(".form-row").addClass('error');
					return;
				}		
				
			}
			
			var button = $(this).find("button[type='submit']");
			if(button && button.data("recaptchav3") == true && typeof grecaptcha != "undefined"){
				 grecaptcha.ready(function() {
			          grecaptcha.execute(button.data("sitekey"), {action: 'submit'}).then(function(token) {
			        	  $("input[name='grecaptcharesponse']").val(token);
			        	  sendDataRegistration(form.serialize(), url, source);
			          });
			     });
			} else {
				sendDataRegistration(data, url, source);
			}
			
		}
	});
	
	$(document).on("submit", '.welcome-lightbox-reg-wrap form', function(e){
		var url = $(this).attr('action'),
			data = $(this).serialize(),
			form = $(this);
		
		e.preventDefault();
		e.stopPropagation();
		if($(".js-super-extra-registration input[name*='login_password']:eq(0)").val() != $(".js-super-extra-registration input[name*='login_password']:eq(1)").val()){
			$(".js-super-extra-registration input[name*='login_password']").addClass('error');
			return;
		}
		
		if($(".js-super-extra-registration input[name*='firstname']").length && !$(".js-super-extra-registration input[name*='firstname']").val()){
			$(".js-super-extra-registration input[name*='firstname']").parents(".form-row").addClass('error');
			return;
		}
		
		if($(".js-super-extra-registration input[name*='lastname']").length && !$(".js-super-extra-registration input[name*='lastname']").val()){
			$(".js-super-extra-registration input[name*='lastname']").parents(".form-row").addClass('error');
			return;
		}

		
		if(form.find(".profile-birthday").length){
			var date_1 = form.find("select.day-of-birthday").val();
			var date_2 = form.find("select.month-of-birthday").val();
			var date_3 = form.find("select.year-of-birthday").val();
			if(date_1 > 0 && date_2 > 0 && date_3 > 0){
				if(getAge(date_2 + "/" + date_1 + "/" + date_3) < 18){
					$(".error-message-18").show();
					$(".error-message-18").parents(".form-row").addClass('error');
					return;	
				} 
			} else {
				$(".error-message-18").show();
				$(".error-message-18").parents(".form-row").addClass('error');
				return;
			}					
		}
		
		
		
		var button = $(this).find("button[type='submit']");
		if(button && button.data("recaptchav3") == true && typeof grecaptcha != "undefined"){
			 grecaptcha.ready(function() {
		          grecaptcha.execute(button.data("sitekey"), {action: 'submit'}).then(function(token) {
		        	  $("input[name='grecaptcharesponse']").val(token);
		        	  sendLightBoxRegistrationData(form.serialize(), url);
		          });
		     });
		} else {
			sendLightBoxRegistrationData(data, url);
		}
		
		
	});
	
	function sendLightBoxRegistrationData(data, url){
		$.ajax({
			type : 'POST',
			url : url,
			data : data,
			success : function(data) {
				if (data.success == true) {					
					$('.welcome-lightbox-reg-step.step-2').hide();
					$('.welcome-lightbox-reg-step.step-1').hide();
					$('.welcome-lightbox-reg-step.step-3').hide();
					$('.welcome-lightbox-reg-step.step-4').css('display', 'flex');					
				} else {
					//проверку что была ошибка перепроверьте поля					
					window.location.reload();
				}
			}
		});
	}

	if (window.location.href.indexOf("popupRegistration") > 0) {
		$('.js-ajax-form-submit.ajax-signin-popup').trigger('click');
	}

	if (window.location.href.indexOf("popupCompleteProfile") > 0) {
		$('.si--su__redirect .js-ajax-form-submit').first().trigger('click');
	}

	if (window.location.href.indexOf("popupCreateAccount") > 0) {
		$('.si--su__redirect .ajax-registration-popup').trigger('click');
	}
	
	if(window.location.href.indexOf('showQuickview') > -1){
		var url = new URL(window.location.href),
		    itemID = url.searchParams.get('showQuickview'),
		    pattern = new RegExp("^[a-zA-Z0-9]+$");
		if (pattern.test(itemID)){
			app.quickView.show({
	            id : itemID,
	            source : "quickview"
	        });
		}
	}

	if($(".js-header-logout").length && $(".js-pt_account").length){
		$(".js-header-logout a").attr("href", $(".js-header-logout a").data("hrefaccount"));
	}

	//fivestars landing
	if($('#fivestar-sidebar').length) {
		try {
			var stickySidebar = new StickySidebar('#fivestar-sidebar', {
				topSpacing: 135,
				bottomSpacing: 0,
				containerSelector: '.five-star--container',
				resizeSensor: true,
				innerWrapperSelector: '.sidebar__inner'
			});
		}catch(e){}
	}

	if($(".five-star--container").length){
//		$('.TT3ShowMoreText').html('LOAD MORE REVIEWS');
		$('.five-star--product-tabs-nav-item a').click(function (e) {
			e.preventDefault();

			var tab = $(this).data('tab'),
				tabActiveProduct = $(this).parent().data('product');
			
			if (tab == '1') {
				//fix for Sanaa videos
				$('.fs--video__wrapper.wrapper__2').slick('slickNext');
				$('.fs--video__wrapper.wrapper__2').slick('slickPrev');
			}
			
			if (tab !== '' && tab !== undefined) {
				$('.five-star--product-tabs-nav-item.active').removeClass('active');
				// $(tabActiveProduct).parent().addClass('active');
				$( "div[data-product='" + tabActiveProduct + "']" ).addClass('active')
				$('.five-star--product-tabs-item.active').removeClass('active');
				$('.five-star--product-tabs-item[data-tab="'+tab+'"]').addClass('active');
				$('.fivestars--reviews-wrapper.active').removeClass('active');
				$('.fivestars--reviews-wrapper[data-tab="'+tab+'"]').addClass('active');

				if ($(this).parents('.nav__backtotop').length > 0) {
					$("html, body").animate({ scrollTop: 0 }, 500);
				}
			}
		});
	}

	/**
	 * Credit card scan on checkout on IOS
	 */
	if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
		if ($('.js-cc-input').length > 0) {
			var $input = $('.js-cc-input input');
			var demandwareName = $input.attr('name');
			$input.on('focus', function () {
				$input.attr('name', 'cardnumber');
			});
			
			$input.on('mouseover mouseleave change', function () {
				$input.attr('name', demandwareName);
			});	
		}
	}
	
	
    if($(".native-order-history").length){
    	$("td").attr("style", "");
    }
    $(document).on("click", ".js-resend-email", function(e){
    	e.preventDefault();
    	var href = $(this).attr("href");
    	$.get(href, function(){    		
    		$(".js-resend-email").after('<strong>Sent</strong>');
    		$(".js-resend-email").remove();
    	});
    });
    
    function checkBF(){
    	if(getCookie('bfx.country') && getCookie('bfx.country').length && getCookie('bfx.country') != 'US' && typeof bfx != "undefined"){
        	$("body").addClass('borderfree-enable');
        }
    }
    
    checkBF();
    
    setTimeout(function(){
    	checkBF();
    }, 3000);
    
    if (window.matchMedia("(min-width: 960px)").matches) {
    	var cc = 0;
        var moveBfxFlag = setInterval(function() {
            cc++;
            if ($("body > .bfx-cc-wrapper").length) {
                clearInterval(moveBfxFlag);
                $("<li class='singleLink bfx-replace-container'></li>").insertAfter($("#select--country"));
                $("body > .bfx-cc-wrapper").removeClass("bfx-cc-position-right bfx-cc-position-left bfx-cc-position-top bfx-cc-position-bottom").appendTo($(".bfx-replace-container"));
                
                /*-----------KIP-3861--------------*/
                //$('.bfx-cc-collapsed').wrap('<a href="' + $('#select--country a').attr('href') + '"></a>');
                //$('#bfx-cc-wrapper-expanded').remove();
                /*----------/KIP-3861--------------*/
                
            }
            if (cc == 60) {
                clearInterval(moveBfxFlag);
            }
        }, 1000);
    }
    
	
    
    if(window.location.href.indexOf('stl_param_hash') > -1){
    	var url = new URL(window.location.href);
    	var stl_param_hash = url.searchParams.get('stl_param_hash');
    	$("input[type='password']").after('<input type="hidden" name="stl_param_hash" value="'+stl_param_hash+'">');
    	if($(".account-options").length){
    		$(".account-options a").each(function(){
    			$(this).attr("href", $(this).attr("href") + "?stl_param_hash="+stl_param_hash);
    		});
    	}
    }
    if($(".js-customer-info-wrapper").length){
    	$.get(window.app.urls.includeHeaderCustomerInfo, function(data){
        	$(".js-customer-info-wrapper").replaceWith(data);
        	
        	function getCookie(name) {
        	    var nameEQ = name + "=";
        	    var ca = document.cookie.split(';');
        	    for(var i=0;i < ca.length;i++) {
        	        var c = ca[i];
        	        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        	        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        	    }
        	    return null;
        	}
        	function setCookieDays(name,value,days) {
        	    var expires = "";
        	    if (days) {
        	        var date = new Date();
        	        date.setTime(date.getTime() + (days*24*60*60*1000));
        	        expires = "; expires=" + date.toUTCString();
        	    }
        	    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
        	}
        	
        	var lastpage = getCookie('firstTimeCustomerPage');
        	var currentPage = window.location.href;
        	if (lastpage == null || lastpage == undefined) {
        		setCookieDays('firstTimeCustomerPage', currentPage, 3650);
        	} else {
        		if ((currentPage !== lastpage && getCookie('hideWelcomeLightbox') !== 'true') && (!window.welcomeLightboxExcludeURLs || (window.welcomeLightboxExcludeURLs && (window.welcomeLightboxExcludeURLs.split('|').indexOf('/' + window.location.pathname.split('/').pop()) == -1)))) {
        			$('.welcome-lightbox-reg-wrap').show();
        			if(typeof initCaptchaV3 != "undefined"){
        				initCaptchaV3();
        			}
        			setCookieDays('hideWelcomeLightbox', 'true', 3650);
        		} else {
        			$('.welcome-lightbox-reg-wrap').remove();
        		}
        	}
        });
    }
        
    if($("#mini-cart").length){
    	$.get(window.app.urls.minicart, function(data){
    		$("#mini-cart").empty().append(data);
    		$(".js-mobile-count").text(parseInt($(".js-desktop-count").first().text().trim()));
    		$(".mobile-bar-title").css("visibility", "initial");
    	});
    }
    
    if($(".pt_order-confirmation").length > 0){
    	if (app.isMobileUserAgent) {
    		$(".linc-optin-widget").insertAfter($(".confirmation-thank-you"));
    	}
    }
    
    $(document).on('click', '.promotion .promo-details', function() {
		$(this).toggleClass('active');
	});
    
    //fix to remove emoji
    if($(".pt_checkout").length || $("#RegistrationForm").length || $("#edit-address-form").length){
    	
    	$("input").each(function(){
    		$(this).val($(this).val().replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '').replace(/([#0-9]\u20E3)|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?|[\u20E3]|[\u26A0-\u3000]|\uD83E[\udd00-\uddff]|[\u00A0-\u269F]/g, ''));    		    		
    	});
    	$("input").on('change', function(){
    		$(this).val(  $(this).val().replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '').replace(/([#0-9]\u20E3)|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?|[\u20E3]|[\u26A0-\u3000]|\uD83E[\udd00-\uddff]|[\u00A0-\u269F]/g, '')   );
    	})
    	    	
    }
    
    //VFCemployeePopup functions
    function findGetParameter(parameterName) {
        var result = null,
            tmp = [];
        location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
              tmp = item.split("=");
              if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }
    
    var dlg;
    
    if (findGetParameter('showVFCemployeePopup') == 'true') {
    	//SHOW POPUP
    	dlg = app.dialog.create({target:"#vfc-employee-terms-popup", options:{
			width:600,
			height:'auto',
			dialogClass: 'ui-submit-popup',
			autoOpen:false,
			closeOnEscape: true,
			position: { my: "center center",
			            at: "center center",
			            of: window,			            
				}
			}
		});
    	
    	dlg.dialog("open");
    }
    
    $(document).on('click', '.js-employee-popup-cancel', function(e) {
    	e.preventDefault();    	
    	dlg.dialog("close");
    });
    
    $(document).on('click', '.js-employee-popup-agree', function(e) {
    	e.preventDefault();
    	$.ajax({
    		method: 'get',
    		url: $(this).attr('href'),
    		data: {format: "json"},
    		success: function(data) {
    			window.location.href = window.location.href.split('?')[0];
    		}
    	});
    });

	$(document).on("click", '.js-remove-item-minicart', function(e){
		e.preventDefault();

		var url=$(this).attr("href");
		$.ajax({
			type : 'POST',
			url : url,
			success : function(data){
				// update MiniCart
				$("#mini-cart").empty().append(data);

				var mobileMiniCart = $(".ui-dialog .mini-cart-content");
				if(mobileMiniCart.length){
					var tempContainer = $('<div>').html(data);
					mobileMiniCart.empty().append(tempContainer.find('.mini-cart-content').html());
				}

	    		$(".js-mobile-count").text(parseInt($(".js-desktop-count").first().text().trim()));
	    		$(".mobile-bar-title").css("visibility", "initial");
			}
		});
	});

	$('.tooltip').focus(function(){
		$(this).find('.tooltip-content').removeAttr('style');
	});

	$('.tooltip-close').click(function(){
		$(this).closest('.tooltip-content').hide();
	});
	
	if (typeof dataLayer != "undefined"){
		var headerSearch = $('#header-search form');
		
		headerSearch.submit(function(event){
			event.preventDefault();
			
			dataLayer.push({
				'event': 'search-submit',
				'bloomreachSearch': {
					"q" : headerSearch.find('input').val()
				}
			});
			
			headerSearch.off('submit').submit();
		});
	}

	//migrated from GA EEC DataLayer Click Tracking
	/*
	// related products
	$('body').on('click', '.monetate-endcap .cartridge .item a', function() {

		productId = $(this).attr('href').replace(/^.*\/|\.[^.]*$/g, '');
		productName = $(this).find('.text .name').text().toLowerCase();
		pageType = dataLayer.page.type || '';
		productDiscovery = pageType + ' page - related products';
		
	    productDiscovery = location.hostname + location.pathname;

			eventCategory = 'Ecommerce';
			eventAction = 'Product Click';
			eventLabel = productId + ' ' + productDiscovery;

			dataLayer.push({
				'event': 'gaEvent',
				'eventCategory': eventCategory,
				'eventAction': eventAction,
				'eventLabel': eventLabel,
				'ecommerce': {
					'click': {
						'actionField': {
							'list': productDiscovery
						},
						'products': [{
							'name': productName,
							'id': productId
						}]
					}
				}
			});

		gaRefreshDataLayerObject();
		
	})

	// view full details click
	$('body').on('click', '.view-fulldetails a', function() {

		productId = $(this).attr('href').replace(/^.*\/|\.[^.]*$/g, '');
		productName = $('h1.product-name').text().toLowerCase();
		productDiscovery = dataLayer.page.breadcrumb;
		
		productDiscovery = location.hostname + location.pathname;

			eventCategory = 'Ecommerce';
			eventAction = 'Product Click';
			eventLabel = productId + ' ' + productDiscovery;

			dataLayer.push({
				'event': 'gaEvent',
				'eventCategory': eventCategory,
				'eventAction': eventAction,
				'eventLabel': eventLabel,
				'ecommerce': {
					'click': {
						'actionField': {
							'list': productDiscovery
						},
						'products': [{
							'name': productName,
							'id': productId
						}]
					}
				}
			});

			gaRefreshDataLayerObject();
		
	});

	// search results / category clicks
	$('body').on('click', '.product-tile a', function() {

		productId = $(this).attr('href').replace(/^.*\/|\.[^.]*$/g, '');
		productName = $(this).attr('title').toLowerCase();
		productDiscovery = dataLayer.page.breadcrumb;

		if (productDiscovery == '') {
			if(dataLayer.page.type != 'unknown') {
				productDiscovery = dataLayer.page.type;			
			}
		}
		
		if (productDiscovery == '') {
			productDiscovery = 'unknown: ' + location.hostname + location.pathname;
		}
			
		productDiscovery = location.hostname + location.pathname;

			eventCategory = 'Ecommerce';
			eventAction = 'Product Click';
			eventLabel = productId + ' ' + productDiscovery;

			dataLayer.push({
				'event': 'gaEvent',
				'eventCategory': eventCategory,
				'eventAction': eventAction,
				'eventLabel': eventLabel,
				'ecommerce': {
					'click': {
						'actionField': {
							'list': productDiscovery
						},
						'products': [{
							'name': productName,
							'id': productId
						}]
					}
				}
			});

		gaRefreshDataLayerObject();
		
	});

	//content clicks
	$('body').on('click', '.content-asset a', function() {

		try {
			var assetName = '';
			assetDivId = $(this).find('div').attr('id');
			console.log(assetDivId);
			assetHref =  $(this).attr('href');
			console.log(assetHref);
		} catch(e) {
			var assetName = 'Unknown Asset';
		}

		eventCategory = 'Content Click Tracking';
		eventAction = 'Content Banner (' + dataLayer.page.breadcrumb + ')';
		eventLabel = 'Image' + $(this).find('img').index() + ': ' + assetName;
		
		dataLayer.push({
		  'event' : 'gaEvent',
		  'eventCategory' : eventCategory,
		  'eventAction' : eventAction,
		  'eventLabel' : eventLabel
		});
		
		gaRefreshDataLayerObject();
		
		console.log(this);
		console.log('Analytics: Track eventCategory: ' + eventCategory);
		console.log('Analytics: Track eventAction: ' + eventAction);
		console.log('Analytics: Track eventLabel: ' + eventLabel);

	});
	
	$(document).on('click', '#quickviewbutton', function(){
		console.log('quickview event fire');
		
		setTimeout(function() {
			productId = $('#pdp-main .product-number span:first').text();
			productName = $('#pdp-main .product-name:first').text().toLowerCase();
			productPrice = $('#pdp-main .product-price span:first').text().trim().replace('$', '');
			if ($('.price-sales').length > 0) {
				productPrice = $('#pdp-main .product-price span.price-sales:first').text().trim().replace('$', '');
			}
			productCategory = dataLayer.page.breadcrumb.toLowerCase();
			if (productCategory.indexOf('factory') > 0) {
				productBrand = 'kipling factory'
			} else {
				productBrand = 'kipling'
			}
			productDiscovery = location.hostname + location.pathname;
			
			dataLayer.push({
				'event': 'productQuickView',
				'ecommerce': {
					'detail': {
						'actionField': {
							'list': productDiscovery
						},
						'products': [{
							'name': productName,
							'id': productId,
							'price': productPrice,
							'category': productCategory,
							'brand': productBrand
						}]
					},
					'click': {
						'actionField': {
							'list': productDiscovery
						},
						'products': [{
							'name': productName,
							'id': productId
						}]
					}
				}
			});
			gaSetProductInfo(productId, productName, productCategory, productBrand);			
			gaRefreshDataLayerObject();
		}, 2000);
		
	});
	
	function gaSetProductInfo(id, name, category, brand) {
		gaProductInfo = JSON.parse(gaReadCookie('gaDiscovery')) || [];
		gaProductInfo.push({
			'id': id,
			'name': name,
			'category': category,
			'brand': brand
		});
		
		gaCreateCookie('gaDiscovery', JSON.stringify(gaProductInfo), 0);
	}
	
	
	function gaRefreshDataLayerObject() {
		try {
			window.dataLayerMerge = dataLayer.reduce(function(result, currentObject) {
				for (var key in currentObject) {
					if (currentObject.hasOwnProperty(key)) {
						result[key] = currentObject[key];
					}
				}
				return result;
			}, {});
			window.dataLayerObject = window.dataLayerMerge;
		} catch (e) {}
	}
	*/
	
	//welcome-lightbox-reg-step step-3 It is already kipling me member после проверки имейла
	//welcome-lightbox-reg-step step-4 после заполнения второго степа
	
	$(document).on('click', '.next-step-lightbox-form', function(e){
		e.preventDefault();
		try {
			$.select2();
		}catch(e){}
		
		//validation
		var step = $(this).closest('.welcome-lightbox-reg-step');
		var valid = true;
		
		if (step.find('[data-email="true"]').val() == '' || step.find('[data-email="true"]').val() == null) {
			valid = false;
			step.find('[data-email="true"]').addClass('error');
		}
		
		if (!step.find('.input-checkbox').is(':checked')) {
			valid = false;
			step.find('.input-checkbox').parent().find('label').addClass('error');
		}
		
		if (valid) {
			var url = $(".js-super-extra-registration").data('checkcustomer')+"?email="+step.find('[data-email="true"]').val();
			$.get(url, function(data){
				//customer registered	
				$('.welcome-lightbox-reg-step.step-1').hide();
				if(data.success == true){
					$('.welcome-lightbox-reg-step.step-3').css('display', 'flex');
				} else {
					$('.welcome-lightbox-reg-step.step-2').css('display', 'flex');
				}
			});
		}
	});
	
	$(".js-super-extra-registration button[type='submit']").click(function (e) {		
		var form = $(".js-super-extra-registration");
		if(form.find(".profile-birthday").val() == ''){
			e.preventDefault();
			$(".month-of-birthday--wrapper").parents(".form-row").addClass('error');
			return false;
		} else {
			$(".js-super-extra-registration button[type='submit']").unbind( "click" ).click();			
		}
	});
	
	$(document).on('change', '.welcome-lightbox-reg-step .input-checkbox', function(){
		$(this).parent().find('label').removeClass('error');
	});
	
	$(document).on('change', '.welcome-lightbox-reg-step input', function(){
		$(this).removeClass('error');
	});
	
	$(document).on('click', '.welcome-lightbox-reg-close', function(e){
		e.preventDefault();
		$('.welcome-lightbox-reg-wrap').hide();
	});
	
	$(document).on('change', '.welcome-lightbox-reg-step [data-firstname]', function(){
		$('.js-name-welcome').html($(this).val().trim());
	});
	
	window.app.checkUserGeoLocation = function(){
		$.get(window.app.urls.usergeolocationJSON, function(data){
    		console.log("GeoLocation data: ", data);
    	});
	}


	$('.custom-carousel-box').each(function(){

		var box = $(this);
		
		customCarouselURL = app.util.appendParamsToUrl(app.urls.getCustomCarousel, {
			type: box.attr('data-type'),
			items: box.attr('data-items'),
			sort: box.attr('data-sort')
		});
		
		$.ajax({
			method: 'GET',
			url: customCarouselURL,
			success: function(data){

				box.append(data);

				if(box.find('.product_recommender').length){

					box.wrap('<div class="custom-carousel-box-wrapper"></div>');

					box.find(".product_recommender-item .product-image img").on('error', function(){				
						$(this).parents('li').remove();
					});

					setTimeout(function() {
						box.find('.product_recommender-list').not('.slick-initialized').on('init reInit', function(slick) {
						  	app.product.tile.init($('.product_recommender-wrap'));
						  	$(document).trigger('recommender-init');
						  	box.addClass('is-visible');
						}).slickLazy({
				            infinite: true,
				            slidesToShow: 3,
				            slidesToScroll: 1,
				            arrows: true,
				            responsive: [
							    {
							      breakpoint: 960,
							      settings: {
							        slidesToShow: 2,
							      }
							    }
							]
				        });
					}, 100);

				}

			},
			error: function (e) {
				console.log(e);
			}
		});

	});

	/*----------------Product Set page fixed left column-------------*/

	function productSetFixedCol(){

	    if(window.matchMedia('(min-width: 768px)')){

            var col = $('.product-set-alt-wrapper');
            var topOffset = col.offset().top;
            var box = col.find('.product-set-about-box');
            var header = $('header').outerHeight();

            if (col.outerHeight() > box.outerHeight() && $(window).height() > box.outerHeight()){

                var scroll = $(window).scrollTop() + header;
                var delta = col.outerHeight() - box.outerHeight();

                if (scroll > topOffset){
                    if(scroll < delta + topOffset){
                        box.addClass('is-fixed').removeClass('is-absolute').css('top',header);
                    } else {
                        box.removeClass('is-fixed').addClass('is-absolute').removeAttr('style');
                    }
                } else {
                    box.removeClass('is-fixed').removeClass('is-absolute').removeAttr('style');
                }

            } else {
                box.removeClass('is-fixed').removeClass('is-absolute').removeAttr('style');
            }

        }
    }

    if($('.product-set-alt').length){

	    $(document).ready(function(){
	    	productSetFixedCol();
		});

		$(window).on('load',function(){
	    	productSetFixedCol();
		});

		$(window).scroll(function(){
	    	productSetFixedCol();
		});

		$(window).resize(function(){
	    	productSetFixedCol();
		});

	}

	/*----------------/Product Set page fixed left column-------------*/
    
    window.klarnaExpressButtonAsyncCallback = function(){
		 Klarna.ExpressButton.on('user-authenticated', function (callbackData) { 			
			 $.ajax({
				'type' : 'POST',
				'data' : callbackData,
				'dataType' : 'json',
				'url' : app.urls.coBillingSetKlarna,
				success : function(){
					window.location.href = app.urls.coBillingKlarnaSelected;
				}
			 });
		   console.log('Done');
		   console.log(callbackData);
		 })
	};

}(window.app = window.app || {}, jQuery));
